import type { FC } from 'react';

import MuiModal from '@mui/material/Modal';

import CloseIcon from '../../../assets/svg/close.svg?react';
import Button from '../Button/Button';
import {
  buttonCSS,
  buttonsWrapperCSS,
  closeIconCSS,
  containerCSS,
  contentCSS,
  overlayBlurCSS,
  overlayCSS,
  titleCSS,
  titleWrapperCSS,
} from './Modal.style';
import { ButtonDirection, type ModalProps, ModalSize } from './Modal.type';

const Modal: FC<ModalProps> = ({
  customCSS,
  children,
  title,
  isOpen,
  size = ModalSize.Medium,
  isFullScreen = false,
  blurBackdrop = false,
  isLoading = false,
  actionButtons,
  buttonDirection = ButtonDirection.Row,
  onClose,
  subtitle,
}) => {
  const handleBackgroundClick = (): void => {
    if (isLoading || !onClose) {
      return;
    }

    onClose();
  };

  return (
    <MuiModal
      open={isOpen}
      onClose={handleBackgroundClick}
      slotProps={{
        backdrop: {
          sx: blurBackdrop ? overlayBlurCSS : overlayCSS,
        },
      }}
    >
      <div css={[containerCSS(isFullScreen, size), customCSS]}>
        { (title || subtitle) && (
          <div css={titleWrapperCSS}>
            {title && (
              <div css={titleCSS(!onClose)}>
                {title}
                {onClose && <CloseIcon css={closeIconCSS} onClick={onClose} />}
              </div>
            )}
            {subtitle && <div>{subtitle}</div>}
          </div>
        )}
        <div css={contentCSS(isFullScreen)}>{children}</div>
        {actionButtons && actionButtons.length > 0 && (
          <div css={buttonsWrapperCSS(isFullScreen)}>
            {actionButtons.map(({ text, ...buttonProps }) => (
              <Button key={text} customCSS={buttonCSS(buttonDirection)} isLoading={isLoading} {...buttonProps}>
                {text}
              </Button>
            ))}
          </div>
        )}
      </div>
    </MuiModal>
  );
};

export default Modal;
