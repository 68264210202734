import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import InfoRequestTooltip from '../../../components/InfoRequestTooltip/InfoRequestTooltip.tsx';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import PremiumGraph from '../PremiumGraph/PremiumGraph.tsx';
import { CHART_DATA_SET } from '../PremiumGraph/PremiumGraph.utils.ts';
import usePremiumHistoryQuery from '../../../api/history/use-premium-history-query.ts';
import { HistoryDataStatuses } from '../../../types/history.type.ts';
import {
  addressCSS,
  chartCSS,
  hintCSS,
  notificationCSS,
  titleCSS,
} from '../../../styles/chart.style.ts';
import { getChartState, transformPremiumHistoryData } from '../../../utils/chart.util.ts';
import SkeletonLoader from '../../../shared/components/SkeletonLoader/SkeletonLoader.tsx';
import useQuotesQuery from '../../../api/quotes/use-quotes-query.ts';
import { QuotingStatus } from '../../../types/quote.type.ts';
import { AnalyticPageKey } from '../../../types/analytics.type.ts';
import InfoPanel from '../../../components/InfoPanel/InfoPanel.tsx';
import { pageInfoPanelCSS } from '../../../styles/layout.style.ts';
import { useDynamicTextReplacements } from '../../../hooks/use-dynamic-text-replacements.tsx';
import ReplacedText from '../../../shared/components/ReplacedText/ReplacedText.tsx';
import useResponsive from '../../../hooks/use-responsive.ts';
import { TEST_ID } from '../../../constants/test-id.ts';
import type { PremiumHistoryProps } from './PremiumHistory.type.ts';
import { getInfoPanelContent } from './PremiumHistory.utils.ts';

const PremiumHistory: FC<PremiumHistoryProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData, isLoading: userQueryIsLoading } = useAccountQuery(gid);
  const {
    data: quotesData,
    isLoading: quotesQueryIsLoading,
  } = useQuotesQuery(gid, !!accountData?.account.profile_completed_at);
  const {
    data: historyData,
    isLoading: historyQueryIsLoading,
  } = usePremiumHistoryQuery(gid, quotesData?.quotes_request_status === QuotingStatus.Finished);
  const dynamicTextReplacements = useDynamicTextReplacements();
  const { isDesktop } = useResponsive();

  const address = accountData?.account.address ? accountData.account.address.line1 : '';

  const chartState = getChartState(
    quotesData?.quotes_request_status,
    accountData,
    quotesData,
    historyData,
  );

  if (userQueryIsLoading || quotesQueryIsLoading || historyQueryIsLoading) {
    return (
      <>
        <SkeletonLoader width={75} absoluteHeight={32} />
        <SkeletonLoader width={65} marginTop={9} marginBottom={24} />
        <SkeletonLoader width={100} absoluteHeight={243} />
      </>
    );
  }

  const infoPanelContent = getInfoPanelContent(historyData);

  const getContent = () => {
    switch (chartState) {
      case HistoryDataStatuses.Ready:
        return (
          <div css={chartCSS(false)}>
            <PremiumGraph data={transformPremiumHistoryData(historyData!.history)} hidePreview={false} />
            <div css={hintCSS}>Hint: {isDesktop ? 'Hover' : 'Tap'} over graph to see data per year.</div>

            {infoPanelContent && (
              <InfoPanel
                customCSS={pageInfoPanelCSS}
                message={(
                  <ReplacedText
                    replacements={dynamicTextReplacements}
                    template={infoPanelContent.message}
                  />
                )}
                type={infoPanelContent.type}
              />
            )}
          </div>
        );
      case HistoryDataStatuses.Pending:
        return (
          <div css={chartCSS()}>
            <PremiumGraph data={transformPremiumHistoryData(CHART_DATA_SET.history)} />
            <div css={notificationCSS}>
              <h3>Almost There!</h3>
              <p>We are still fetching quotes for your home. We will update you when the process is complete.</p>
            </div>
          </div>
        );
      case HistoryDataStatuses.Unknown:
        return (
          <div css={chartCSS()}>
            <PremiumGraph data={transformPremiumHistoryData(CHART_DATA_SET.history)} />
            <InfoRequestTooltip page={AnalyticPageKey.RateReview} />
          </div>
        );
    }
  };

  return (
    <div css={customCSS}>
      <div css={titleCSS}>Your Premium vs Matic’s Lowest Quote*</div>
      <p css={addressCSS} data-testid={TEST_ID.ADDRESS}>🏠 {address}</p>
      {getContent()}
    </div>
  );
};

export default PremiumHistory;
