import { type FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useScoreQuery from '../../../api/score/use-score-query.ts';
import HeartInShieldIcon from '../../../assets/svg/heart-in-shield.svg?react';
import HouseScoreIcon from '../../../assets/svg/house-score.svg?react';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import { ScoreType } from '../../../types/score.type.ts';
import ScoreCard from '../ScoreCard/ScoreCard.tsx';
import { ScoreGaugeType } from '../ScoreCard/ScoreCard.type.ts';
import ROUTES from '../../../constants/routes.ts';
import { DpCta } from '../../../types/digital-profile.type.ts';
import useDpNavigate from '../../../hooks/use-dp-navigate.ts';
import useSegment from '../../../hooks/use-segment.ts';
import { AnalyticLocation, AnalyticPageKey } from '../../../types/analytics.type.ts';
import ScoreProfileModal from '../../../components/ScoreProfileModal/ScoreProfileModal.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import { NonOptionalPolicyInfoSchema } from '../../../api/score/score-query.schema.ts';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import useQuotesQuery from '../../../api/quotes/use-quotes-query.ts';
import type { ScoreCardsProps } from './ScoreCards.type.ts';
import {
  HOW_WE_CALCULATE_PROTECTION_SCORE,
  HOW_WE_CALCULATE_RISK_SCORE,
  SCORE_DETAILS,
  revertRiskScoreValue,
} from './ScoreCards.util.ts';
import { containerCSS, scoreCardCSS } from './ScoreCards.style.ts';

const ScoreCards: FC<ScoreCardsProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: riscScoreData } = useScoreQuery(gid, ScoreType.Risk);
  const { data: protectionScoreData } = useScoreQuery(gid, ScoreType.Protection);
  const navigate = useNavigate();
  const navigateToDp = useDpNavigate();
  const { track } = useSegment();
  const { data: accountData } = useAccountQuery(gid);
  const { data: quotesData } = useQuotesQuery(gid, !!accountData?.account.profile_completed_at);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigateDigitalProfile = (location: AnalyticLocation) => {
    track('Unlock Portal Data Requested', {
      page: AnalyticPageKey.Insights,
      location,
    });
    navigateToDp(DpCta.Score, AnalyticPageKey.Insights);
  };

  const navigateFactors = (scrollToId: 'scroll-to-risk' | 'scroll-to-protection') => {
    track('Insights Navigation Clicked', {
      target_page: AnalyticPageKey.SnapshotDetails,
    });
    navigate(`${ROUTES.snapshotDetails.replace(':gid', gid)}`, { state: { scrollToId } });
  };

  const cardLabelContent = () => {
    if (!quotesData?.quotes.length)
      return null;

    const isProtectionScoresFilledOut = NonOptionalPolicyInfoSchema.safeParse(protectionScoreData?.policy_info);
    const incompleteTexts = ['Your score profile is incomplete.', 'Complete now.'];
    const completeTexts = ['Your score profile is complete.', 'Review and update.'];

    const texts = isProtectionScoresFilledOut.success ? completeTexts : incompleteTexts;

    return (
      <>
        {texts[0]}
        <Button variant="text" onClick={() => setIsModalOpen(true)}>{texts[1]}</Button>
      </>
    );
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <ScoreCard
        customCSS={scoreCardCSS}
        title="Risk Score"
        description={SCORE_DETAILS.risk}
        icon={<HeartInShieldIcon />}
        scoreGaugeType={ScoreGaugeType.Negative}
        score={revertRiskScoreValue(riscScoreData?.score.value, riscScoreData?.score.max_value)}
        scoreMax={riscScoreData?.score.max_value}
        factors={riscScoreData?.score.factors}
        tooltip={HOW_WE_CALCULATE_RISK_SCORE}
        scoreButtonLabel="View all risk factors"
        isLoading={!riscScoreData || !protectionScoreData}
        onViewAllFactorsClick={() => navigateFactors('scroll-to-risk')}
        onClick={() => navigateDigitalProfile(AnalyticLocation.RiskScoreCard)}
      />
      <ScoreCard
        customCSS={scoreCardCSS}
        title="Protection Score"
        description={SCORE_DETAILS.protection}
        icon={<HouseScoreIcon />}
        scoreGaugeType={ScoreGaugeType.Positive}
        score={protectionScoreData?.score.value}
        scoreMax={protectionScoreData?.score.max_value}
        factors={protectionScoreData?.score.factors}
        tooltip={HOW_WE_CALCULATE_PROTECTION_SCORE}
        scoreButtonLabel="View all protection factors"
        isLoading={!protectionScoreData || !riscScoreData}
        onViewAllFactorsClick={() => navigateFactors('scroll-to-protection')}
        onClick={() => navigateDigitalProfile(AnalyticLocation.ProtectionScoreCard)}
        label={cardLabelContent()}
      />

      <ScoreProfileModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data={protectionScoreData?.policy_info || {}}
      />
    </div>
  );
};

export default ScoreCards;
