import type { FC } from 'react';
import useResponsive from '../../../hooks/use-responsive';
import SkeletonLoader from '../../../shared/components/SkeletonLoader/SkeletonLoader';
import FactorItem from '../../../components/FactorItem/FactorItem';
import type { Factor } from '../../../types/score.type';
import { factorCSS, factorsContainerCSS } from './FactorsList.style';

const FactorList: FC<{ factors: Array<Factor & { order: number }> }> = ({ factors }) => {
  const { isMobile } = useResponsive();

  const renderLoadingFactors = () => {
    const count = isMobile ? [1, 2, 3] : [1, 2, 3, 4, 5, 6];
    return count.map(key => <SkeletonLoader key={key} customCSS={factorCSS} absoluteHeight={40} width={100} />);
  };

  return (
    <div css={factorsContainerCSS}>
      {factors.length
        ? factors
          .map(f => (
            <FactorItem
              customCSS={factorCSS}
              key={f.key}
              title={f.title}
              titleHint={f.tooltip}
              value={f.display_value}
              valueHint={f.explanation}
              riskLevel={f.risk}
              impactLevel={f.impact_level}
            />
          ))
        : renderLoadingFactors()}
    </div>
  );
};

export default FactorList;
