import { useEffect } from 'react';
import useCreateFullStorySession from '../api/full-story-sessions/use-create-full-story-session';

function useTrackFullStorySession({
  personGid,
  engagementGid,
  proposalGid,
  isOpenedFromAmp,
}: {
  personGid?: string;
  engagementGid?: string;
  proposalGid?: string;
  isOpenedFromAmp?: boolean;
}) {
  const { mutate: createFullStorySession } = useCreateFullStorySession();
  useEffect(() => {
    // Remove timeout and use FS.isInitialized() callback after migration to FullStory NPM Package
    // Or FS's async methods if FS is migrated to V2
    const timeoutId = setTimeout(() => {
      const fullStory = (window as any).FS;
      if (isOpenedFromAmp || !fullStory) {
        return;
      }
      const sessionUrl = fullStory.getCurrentSessionURL && fullStory.getCurrentSessionURL();

      if (personGid && engagementGid && proposalGid && sessionUrl) {
        createFullStorySession({ personGid, engagementGid, sessionUrl, proposalGid });
      }
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [personGid, engagementGid, proposalGid, isOpenedFromAmp, createFullStorySession]);
}

export default useTrackFullStorySession;
