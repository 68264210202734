// FIXME some problem with such memo exports need to be fixed later

import { type FC, memo } from 'react';

import { visuallyHiddenCSS } from '../../shared.style';
import { validationErrorCSS } from './ValidationError.style';
import type { ValidationErrorProps } from './ValidationError.types';

const ValidationError: FC<ValidationErrorProps> = ({ customCSS, visible = false, heading, children }) =>
  visible
    ? (
        <div css={[validationErrorCSS, customCSS]} role="alert" aria-live="polite">
          <span css={visuallyHiddenCSS}>{typeof heading === 'string' ? `${heading} error: ` : 'Error: '}</span>
          {children}
        </div>
      )
    : null;

export default memo(ValidationError);
