import type { FC } from 'react';
import FactorItem from '../../../components/FactorItem/FactorItem.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import { actionButtonCSS, factorCSS, factorsContainerCSS, titleCSS } from './ScoreFactors.style';
import type { ScoreFactorsProps } from './ScoreFactors.type';

const ScoreFactors: FC<ScoreFactorsProps> = ({ customCSS, factors, scoreButtonLabel, onActionClick }) => {
  return (
    <div css={customCSS}>
      <div css={titleCSS}>Top Factors</div>

      <div css={factorsContainerCSS}>
        {factors.slice(0, 3).map(f => (
          <FactorItem
            customCSS={factorCSS}
            key={f.key}
            title={f.title}
            titleHint={f.tooltip}
            value={f.display_value}
            valueHint={f.explanation}
            riskLevel={f.risk}
            impactLevel={f.impact_level}
          />
        ))}
      </div>
      <Button customCSS={actionButtonCSS} type="button" variant="secondary" onClick={onActionClick} size="small">
        {scoreButtonLabel}
      </Button>
    </div>
  );
};

export default ScoreFactors;
