import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import useQuotesQuery from '../../../api/quotes/use-quotes-query.ts';
import ListLoadingSkeleton from '../../../components/ListLoadingSkeleton/ListLoadingSkeleton.tsx';
import ROUTES from '../../../constants/routes.ts';
import useDpNavigate from '../../../hooks/use-dp-navigate.ts';
import { DpCta } from '../../../types/digital-profile.type.ts';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import useMutateCustomerChoice from '../../../api/quote/use-mutate-customer-choice.ts';
import useSegment from '../../../hooks/use-segment.ts';
import useMutateLeadCreation from '../../../api/lead/use-mutate-lead-creation.ts';
import { AnalyticLocation, AnalyticPageKey } from '../../../types/analytics.type.ts';
import CarriersLockList from '../CarriersLockList/CarriersLockList.tsx';
import QuotesList from '../QuotesList/QuotesList.tsx';
import useAvailableCarriersQuery from '../../../api/available-carriers/use-available-carriers-query.ts';
import Notification from '../../../shared/components/Notification/Notification.tsx';
import { reportAPIExecutionError } from '../../../utils/error.util.ts';
import type { CarrierOptionsListProps } from './CarrierOptionsList.type.ts';

const CARRIER_OPTIONS_LIMIT = 10;

const CarrierOptionsList: FC<CarrierOptionsListProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const navigateToDp = useDpNavigate();
  const { data: accountData } = useAccountQuery(gid);
  const { data: appointedCarriersData } = useAvailableCarriersQuery(gid, true);
  // Prefetch other carriers data while quotes are loading
  useAvailableCarriersQuery(gid, false);
  const { data: quotesData } = useQuotesQuery(gid, !!accountData?.account.profile_completed_at);
  const { mutateAsync: submitCustomerChoice } = useMutateCustomerChoice(gid);
  const { mutateAsync: createLead } = useMutateLeadCreation(gid);

  const navigate = useNavigate();
  const [updatingQuoteId, setUpdatingQuoteId] = useState<string | null>(null);
  const [showSelectError, setShowSelectError] = useState(false);

  const { track } = useSegment();

  const isDataLoading = !accountData
    || !appointedCarriersData
    || (!quotesData && accountData.account.profile_completed_at);

  const handleQuoteUnlock = () => {
    track('Unlock Portal Data Requested', {
      page: AnalyticPageKey.CarrierOptions,
      location: AnalyticLocation.CarrierOptionsList,
    });
    navigateToDp(DpCta.Quotes, AnalyticPageKey.CarrierOptions);
  };

  const handleQuoteChoose = async (quoteGid: string, index: number) => {
    const quote = quotesData?.quotes.find(q => q.gid === quoteGid);

    if (!quote) {
      return;
    }

    setUpdatingQuoteId(quoteGid);
    track('Quote Selected', {
      page: AnalyticPageKey.CarrierOptions,
      location: AnalyticLocation.CarrierOptionsList,
      quote_gid: quoteGid,
      carrier_key: quote.carrier.key,
      ordinal: index + 1,
      policy_type: quote.policy_type,
      premium_value: Number(quote.premium),
    });
    try {
      await createLead();
      await submitCustomerChoice(quoteGid);
      navigate(ROUTES.scheduleCall.replace(':gid', gid));
    }
    catch (error) {
      reportAPIExecutionError('Failed to select a quote', error);
      setShowSelectError(true);
    }
    setUpdatingQuoteId(null);
  };

  if (isDataLoading) {
    return (
      <ListLoadingSkeleton customCSS={customCSS} numberOfItems={6} />
    );
  }

  return (
    <div css={customCSS}>
      <Notification
        open={showSelectError}
        message="There was an issue selecting a quote. Please try again later."
        onClose={() => setShowSelectError(false)}
      />
      {accountData?.account.profile_completed_at && quotesData?.quotes_request_status
        ? (
            <QuotesList
              optionsLimit={CARRIER_OPTIONS_LIMIT}
              quoteGidInProcess={updatingQuoteId}
              onQuoteSelect={handleQuoteChoose}
            />
          )
        : <CarriersLockList optionsLimit={CARRIER_OPTIONS_LIMIT} handleQuoteUnlock={handleQuoteUnlock} />}
    </div>
  );
};

export default CarrierOptionsList;
