import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';

export const modalCustomCSS = css`
  border-radius: 8px;
  text-align: center;
  padding: 67px 62px 60px;
  max-width: 458px;

  h3 {
    margin: 20px 0 8px;
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  ${mq[1]} {
    max-width: 458px;
  }
`;
