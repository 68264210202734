import { type SerializedStyles, css } from '@emotion/react';

import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { ButtonDirection, ModalSize } from './Modal.type';

const fullscreenContainerCSS = css`
  left: 0;
  top: 0;
  transform: none;
  max-height: 100%;
  height: 100%;
  width: 100%;
`;

export const overlayCSS = {
  backgroundColor: 'rgba(51, 51, 51, 0.5)',
};

export const overlayBlurCSS = {
  backgroundColor: 'rgba(0, 0, 0, 0.30)',
  backdropFilter: 'blur(9px)',
};

function getModalSizeWidth(size: ModalSize): string {
  switch (size) {
    case ModalSize.Small:
      return '400px';
    case ModalSize.Medium:
      return '700px';
    case ModalSize.Large:
      return '1000px';
    default:
      return '700px';
  }
}

export function containerCSS(isFullScreen = false, size: ModalSize): SerializedStyles {
  return css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.gray30};
  box-shadow: 2px 4px 0 0 rgba(0, 0, 0, 0.12);
  padding: 24px;
  max-height: 85%;
  max-width: ${isFullScreen ? '100%' : '400px'};
  width: calc(100% - 36px);
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;

  ${mq[1]} {
    max-width: ${getModalSizeWidth(size)};
    width: calc(100% - 48px);
  }

  ${isFullScreen && fullscreenContainerCSS};
`;
}

export const titleWrapperCSS = css`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export function titleCSS(hideCloseButton: boolean): SerializedStyles {
  return css`
    position: relative;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;

    ${!hideCloseButton
    && css`
      padding-right: 24px;
    `};
  `;
}

export function contentCSS(isFullScreen: boolean): SerializedStyles {
  return css`
  padding: 0 4px;
  /* Extra space for input focus line in scrollable container */
  margin-left: -4px;
  margin-right: -4px;
  ${isFullScreen
  && css`
    overflow-y: auto;
    height: 100%;
  `}
`;
}

const buttonsWrapperWithDividerCSS = css`
  position: relative;
  margin-top: 48px;

  &:before {
    position: absolute;
    left: -24px;
    right: -24px;
    top: -24px;
    content: '';
    display: block;
    height: 1px;
    background-color: ${theme.color.gray30};
    margin-bottom: 24px;
  }
`;

export function buttonsWrapperCSS(withDivider: boolean): SerializedStyles {
  return css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 24px;
  ${withDivider && buttonsWrapperWithDividerCSS};
`;
}

export const closeIconCSS = css`
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

export function buttonCSS(buttonDirection: ButtonDirection): SerializedStyles {
  return css`
  padding: 12px 20px;
  width: ${buttonDirection === ButtonDirection.Row ? 'auto' : '100%'};
  min-width: 92px;
  ${mq[1]} {
    width: auto;
  }
`;
}
