import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';

export const factorsContainerCSS = css`
  display: flex;
  justify-content: space-between;
  gap: 16px 32px;
  flex-wrap: wrap;

  ${mq[0]} {
    gap: 16px 48px;
  }
`;

export const factorCSS = css`
  width: 100%;
  
  ${mq[1]} {
    width: calc(50% - 24px);
  }

  ${mq[2]} {
    flex-basis: 360px;
  }
`;
