export function canAuthenticate(
  token?: string | null,
  accountGid?: string | null,
  personGid?: string | null,
  sessionGid?: string | null,
  isAccountFetched?: boolean,
  hasAccountData?: boolean,
  authSend?: boolean,
): boolean {
  if (accountGid) {
    return Boolean(!authSend && sessionGid && isAccountFetched && !hasAccountData && token);
  }
  else if (personGid) {
    return Boolean(!authSend && sessionGid && token);
  }
  else {
    return false;
  }
}
