import type { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import useDigitalProfileQuery from '../../api/digital-profile/use-digital-profile-query';
import DpHeader from '../../components/DpHeader/DpHeader';
import SIRV_IMAGES from '../../constants/sirv-images';
import useSegment from '../../hooks/use-segment.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import Button from '../../shared/components/Button/Button';
import Container from '../../shared/components/Container/Container';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import type { DefaultRouteParams } from '../../types/route-params.type';
import { containerCSS, customButtonCSS, descriptionCSS, headingCSS } from './index.style';
import type { DpStartPageProps } from './index.type';
import IntroSteps from './IntroSteps/IntroSteps';
import { addSourceParamsToUrl } from './index.util.ts';

const DpIntroLayout: FC<DpStartPageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;

  const [searchParams] = useSearchParams();
  const cta: string = searchParams.get('cta') || '';
  const targetPage = searchParams.get('targetPage') as AnalyticPageKey || AnalyticPageKey.Insights;

  const { data, isLoading } = useDigitalProfileQuery(gid, targetPage);

  const { track } = useSegment();

  useTrackPage(AnalyticPageKey.DigitalProfileIntro);

  const onActionClick = () => {
    if (data?.profile.url) {
      track('Digital Profile Navigated');
      window.location.href = addSourceParamsToUrl(data.profile.url);
    }
  };

  return (
    <>
      <DpHeader />
      <Container customCSS={[containerCSS, customCSS]}>
        <h1 css={headingCSS}>
          <img src={SIRV_IMAGES.maticHeart} alt="Matic" />
          Let’s get started!
        </h1>
        <p css={descriptionCSS}>
          {`In order to unlock your ${cta} we need some additional details on you and your home so that we can`}
          {' '}
          <span>provide custom advice and solutions.</span>
        </p>

        <IntroSteps />

        <Button
          fullWidth
          size="large"
          css={customButtonCSS}
          isLoading={isLoading}
          onClick={onActionClick}
          disabled={!data?.profile?.url}
        >
          {`Unlock my ${cta}`}
        </Button>
      </Container>
    </>

  );
};

export default DpIntroLayout;
