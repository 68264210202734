import type { FC, ReactNode } from 'react';

import type { SerializedStyles } from '@emotion/react';

import { cardCSS } from './Card.style';

interface CardProps {
  customCSS?: SerializedStyles | (SerializedStyles | undefined)[];
  children: ReactNode;
  testID?: string;
}

export const Card: FC<CardProps> = ({ customCSS, children, testID }) =>
  <div css={[cardCSS, customCSS]} data-testid={testID}>{children}</div>;
