import { type FC, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { highlightedDataCSS, highlightedDataWrapperCSS, markCSS } from '../../../styles/chart.style.ts';
import useSize from '../../../hooks/use-size.ts';
import type { TransformedHistoryDataItem } from '../../../types/history.type.ts';
import { formatSuffix, getLatestYearObject, setBarAttributes } from '../../../utils/chart.util.ts';
import THEME from '../../../styles/theme.ts';
import useResponsive from '../../../hooks/use-responsive.ts';
import { TEST_ID } from '../../../constants/test-id.ts';
import type { QuotesOverTimeGraphProps } from './QuotesOverTimeGraph.type.ts';
import { chartCSS, hoverBgCSS, invisibleHoverBgCSS, xTickCSS, yTickCSS } from './QuotesOverTimeGraph.style.ts';

const currentYear = new Date().getFullYear().toString();

const ReplacementCostGraph: FC<QuotesOverTimeGraphProps> = ({ data, hidePreview = true }) => {
  const svgRef = useRef(null);
  const size = useSize(svgRef);
  const { isMobile } = useResponsive();
  const [selectedData, setSelectedData] = useState<TransformedHistoryDataItem | undefined>();

  useEffect(() => {
    if (!hidePreview) {
      setSelectedData(getLatestYearObject<TransformedHistoryDataItem>(data));
    }
  }, [data, hidePreview]);

  useEffect(() => {
    if (!size?.width)
      return;

    const margin = { top: 20, bottom: 30, left: 50 };
    const width = size.width - margin.left;
    const height = 243 - margin.top - margin.bottom;

    // clear the previous content for rerenders
    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x0 = d3.scaleBand()
      .domain(data.map(d => d.year))
      .range([0, width])
      .padding(0.4);

    const [maxValue] = [d3.max(data, d => Math.max(...d.values))!];

    const y = d3.scaleLinear()
      .domain([0, maxValue || 10])
      .range([height, 0])
      .nice();

    const yAxis = svg.append('g')
      .call(
        d3.axisLeft(y)
          .tickFormat(d => `${d}`)
          .tickSize(-width)
          .ticks(5)
          .tickPadding(10),
      );

    yAxis.select('.domain').remove();
    yAxis.selectAll('.tick line')
      .attr('class', 'y-tick')
      .attr('stroke', (_, i) => (i === 0 ? THEME.color.gray80 : THEME.color.gray10));

    // initial position of hoverBg on the last bar
    const lastBarX = x0(data[data.length - 1].year)!;

    const hoverBg = !!maxValue && svg.append('rect')
      .attr('class', 'hover-bg')
      .attr('height', height + 11)
      .attr('width', x0.bandwidth())
      .attr('x', lastBarX)
      .attr('y', -13);

    const groups = svg.selectAll('g.layer')
      .data(data)
      .enter().append('g')
      .attr('transform', d => `translate(${x0(d.year)},0)`)
      .each(function (d) {
        d3.select(this).append('rect')
          .attr('class', 'invisible-hover-bg')
          .attr('width', x0.bandwidth())
          .attr('height', height)
          .attr('value', d.year)
          .on('mouseenter touchstart', (_) => {
            const newX = x0(d.year)!;

            if (hoverBg) {
              hoverBg.transition()
                .duration(500)
                .attr('x', newX);
            }

            setSelectedData(d);
          });
      });

    groups.selectAll('rect.bar')
      .data(d => d.values.map(value => ({ key: 1, value, year: d.year })))
      .enter().append('rect')
      .call(setBarAttributes, x0, y, height, currentYear, 0.4, hidePreview ? 0 : 1000);

    const xAxis = svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3.axisBottom(x0)
          .tickSize(16)
          .tickPadding(1)
          .tickFormat(year => `${year}${formatSuffix(+year, +currentYear, isMobile)}`),
      );

    xAxis.selectAll('.tick line')
      .attr('class', 'x-tick')
      .attr('transform', 'translate(0, -10)');

    xAxis.select('.domain').remove();
  }, [size?.width, data, hidePreview, isMobile]);

  const selectedValue = selectedData?.values?.[0];

  return (
    <>
      {!hidePreview
      && (
        <div css={highlightedDataWrapperCSS}>
          <div css={highlightedDataCSS}>
            <div data-testid={TEST_ID.GRAPH_LEGEND_LABEL_1}>
              <div css={markCSS(THEME.color.azure50)} />
              <p>Quotes in <span>{selectedData?.year}</span></p>
            </div>

            <p data-testid={TEST_ID.GRAPH_LEGEND_VALUE_1}>
              {selectedValue ? `${selectedValue}${formatSuffix(+selectedData.year, +currentYear, false)}` : 'N/A'}
            </p>
          </div>

        </div>
      )}

      <svg
        ref={svgRef}
        css={[chartCSS, yTickCSS, xTickCSS, hoverBgCSS, invisibleHoverBgCSS]}
      />
    </>

  );
};

export default ReplacementCostGraph;
