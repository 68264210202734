import { type FC, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import formatUtil from '../../../utils/format.util.ts';
import { futureMarkCSS, highlightedDataCSS, highlightedDataWrapperCSS, markCSS } from '../../../styles/chart.style.ts';
import useSize from '../../../hooks/use-size.ts';
import type { TransformedHistoryDataItem } from '../../../types/history.type.ts';
import { formatSuffix, getLatestYearObject, setBarAttributes } from '../../../utils/chart.util.ts';
import THEME from '../../../styles/theme.ts';
import TooltipQuestionButton from '../../../shared/components/TooltipQuestionButton/TooltipQuestionButton.tsx';
import ReplacedText
  from '../../../shared/components/ReplacedText/ReplacedText.tsx';
import { useDynamicTextReplacements } from '../../../hooks/use-dynamic-text-replacements.tsx';
import useResponsive from '../../../hooks/use-responsive.ts';
import { TEST_ID } from '../../../constants/test-id.ts';
import type { PremiumGraphProps } from './PremiumGraph.type';
import { chartCSS, hoverBgCSS, invisibleHoverBgCSS, xTickCSS, yTickCSS } from './PremiumGraph.style';
import { EST_TOOLTIP } from './PremiumGraph.utils.ts';

const currentYear = new Date().getFullYear();

const PremiumGraph: FC<PremiumGraphProps> = ({ data, hidePreview = true }) => {
  const svgRef = useRef(null);
  const size = useSize(svgRef);
  const dynamicTextReplacements = useDynamicTextReplacements();
  const { isMobile } = useResponsive();
  const [selectedData, setSelectedData] = useState<TransformedHistoryDataItem | undefined>();

  useEffect(() => {
    if (!hidePreview) {
      setSelectedData(getLatestYearObject<TransformedHistoryDataItem>(data));
    }
  }, [data, hidePreview]);

  useEffect(() => {
    if (!size?.width || !data)
      return;

    const margin = { top: 20, bottom: 30, left: 50 };
    const width = size.width - margin.left;
    const height = 243 - margin.top - margin.bottom;

    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x0 = d3.scaleBand()
      .domain(data.map(d => d.year))
      .range([0, width])
      .padding(0.2);

    const x1 = d3.scaleBand()
      .domain(['0', '1'])
      .range([0, x0.bandwidth()])
      .padding(0);

    const [minValue, maxValue] = [
      d3.min(data, d => Math.min(...d.values))!,
      d3.max(data, d => Math.max(...d.values))!,
    ];

    const y = d3.scaleLinear()
      .domain([minValue ? minValue / 2 : 0, maxValue || 1000])
      .nice()
      .range([height, 0]);

    const yAxis = svg.append('g')
      .call(
        d3.axisLeft(y)
          .tickFormat(d => `$${d}`)
          .ticks(5)
          .tickSize(-width)
          .tickPadding(10),
      );

    yAxis.select('.domain').remove();
    yAxis.selectAll('.tick line')
      .attr('class', 'y-tick')
      .attr('stroke', (_, i) => (i === 0 ? THEME.color.gray80 : THEME.color.gray10));

    // initial position of hoverBg on the last bar
    const lastBarX = x0(data[data.length - 1].year)!;

    const hoverBg = !!maxValue && svg.append('rect')
      .attr('class', 'hover-bg')
      .attr('height', height + 11)
      .attr('width', x0.bandwidth())
      .attr('x', lastBarX)
      .attr('y', -13);

    const groups = svg.selectAll('g.layer')
      .data(data)
      .enter().append('g')
      .attr('transform', d => `translate(${x0(d.year)},0)`)
      .each(function (d) {
        d3.select(this).append('rect')
          .attr('class', 'invisible-hover-bg')
          .attr('width', x0.bandwidth())
          .attr('height', height)
          .attr('value', d.year)
          .on('mouseenter touchstart', (_) => {
            const newX = x0(d.year)!;

            if (hoverBg) {
              hoverBg.transition()
                .duration(500)
                .attr('x', newX);
            }

            setSelectedData(d);
          });
      });

    groups.selectAll('rect.bar')
      .data(d => d.values.map((value, i) => ({ key: i, value, year: d.year })))
      .enter().append('rect')
      .call(setBarAttributes, x1, y, height, `${currentYear}`, 0, hidePreview ? 0 : 1000);

    const xAxis = svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3.axisBottom(x0)
          .tickSize(16)
          .tickPadding(1)
          .tickFormat(year => `${year}${formatSuffix(+year, +currentYear, isMobile)}`),
      );

    xAxis.selectAll('.tick line')
      .attr('class', 'x-tick')
      .attr('transform', 'translate(0, -10)');

    xAxis.select('.domain').remove();
  }, [size?.width, data, hidePreview, isMobile]);

  const [yourSelectedValue, maticSelectedValue] = selectedData ? selectedData.values : [];
  const formattedSuffix = formatSuffix(selectedData ? +selectedData.year : currentYear, currentYear, false);

  return (
    <>
      {
        !hidePreview && selectedData
        && (
          <div css={highlightedDataWrapperCSS}>
            <div css={highlightedDataCSS}>
              <div data-testid={TEST_ID.GRAPH_LEGEND_LABEL_1}>
                <div css={+selectedData.year > currentYear ? futureMarkCSS(true) : markCSS(THEME.color.violet87)} />
                <p>Your <span>{selectedData.year}</span> premium</p>
              </div>

              <p data-testid={TEST_ID.GRAPH_LEGEND_VALUE_1}>
                {yourSelectedValue
                  ? `${formatUtil.money(yourSelectedValue, { prefix: '$', suffix: formattedSuffix })}`
                  : 'N/A'}

                {+selectedData.year > currentYear && (
                  <TooltipQuestionButton
                    size={16}
                    content={
                      <ReplacedText replacements={dynamicTextReplacements} template={EST_TOOLTIP} />
                    }
                  />
                )}
              </p>
            </div>

            <div css={highlightedDataCSS}>
              <div data-testid={TEST_ID.GRAPH_LEGEND_LABEL_2}>
                <div css={+selectedData.year > currentYear ? futureMarkCSS(false) : markCSS(THEME.color.azure50)} />
                <p>Matic’s <span>{selectedData.year}</span> quote</p>
              </div>

              <p data-testid={TEST_ID.GRAPH_LEGEND_VALUE_2}>
                {maticSelectedValue
                  ? `${formatUtil.money(maticSelectedValue, { prefix: '$', suffix: formattedSuffix })}`
                  : 'N/A'}
              </p>
            </div>
          </div>
        )
      }

      <svg
        ref={svgRef}
        css={[chartCSS, yTickCSS, xTickCSS, hoverBgCSS, invisibleHoverBgCSS]}
      />
    </>
  );
};

export default PremiumGraph;
