import type { ModalProps } from '../../shared/components/Modal/Modal.type';
import { ModalSize } from '../../shared/components/Modal/Modal.type';
import { ValidationType } from '../../types/validation.type';
import { modalCustomCSS } from './ScoreProfileModal.style';

export const DWELLING_COV_TOOLTIP = `Dwelling coverage limit, also referred to as Coverage A, can be found on your 
  homeowners insurance policy. This information helps determine whether your policy may help cover some or all of the 
  cost to replace or repair your home, in the event of a covered loss.`;

export const PROPERTY_LIMIT_TOOLTIP = `Personal property coverage limit, also referred to as Coverage C, can be found 
  on your homeowners insurance policy. This information helps determine whether your policy may help cover some or all 
  of the cost to replace your personal items if they are destroyed, damaged, or stolen due to a covered loss.`;

export const LIABILITY_TOOLTIP = `Liability coverage limit, also referred to as Coverage E, can be found on your 
  homeowners insurance policy. This information helps determine whether our policy may help protect you financially 
  if you are found responsible for injuries or damages to others.`;

const MIN_VALIDATION = {
  key: ValidationType.Min,
  value: 9000,
  message: `It looks like one or more the coverage limits you entered is lower than limits typically 
  provided on a home policy. Please review and update your coverage limits as needed.`,
};

const ONLY_DIGITS_VALIDATION = { key: ValidationType.Pattern, value: /^\d+$/, message: 'Only digits allowed' };

export const DEFAULT_SCORE_TEXT_INPUT_VALIDATION = [MIN_VALIDATION, ONLY_DIGITS_VALIDATION];
export const PROPERTY_LIMIT_INPUT_VALIDATION = [{ ...MIN_VALIDATION, value: 1000 }, ONLY_DIGITS_VALIDATION];

export const MODAL_LOADING_PROPS: Omit<ModalProps, 'isOpen' | 'children'> = {
  size: ModalSize.Small,
  customCSS: modalCustomCSS,
};

export const MODAL_FORM_PROPS: Omit<ModalProps, 'isOpen' | 'children'> = {
  size: ModalSize.Medium,
  subtitle: `Your protection score takes into account your current coverage, and the steps
        you’ve taken to help protect your home from damage. Provide the missing coverage details
        below to get a clearer picture of your protection level.`,
  title: 'Complete your score profile',
  actionButtons: [
    {
      text: 'Submit',
      type: 'submit',
      form: 'score-profile-form',
    },
  ],
};
