import type { FC } from 'react';

import { css } from '@emotion/react';

import noop from '../../../utils/noop.ts';
import Loader from '../Loader/Loader.tsx';
import type { ButtonProps } from './Button.type.ts';

import { buttonCSS, iconCSS, loaderWrapperCSS } from './Button.style.ts';

const Button: FC<ButtonProps> = ({
  size = 'middle',
  children,
  variant = 'default',
  isLoading = false,
  customCSS,
  disabled,
  fullWidth,
  type = 'button',
  leftIcon,
  rightIcon,
  onClick,
  ...props
}) => (
  <button
    {...props}
    type={type}
    disabled={disabled}
    css={[buttonCSS(size, variant, fullWidth, !!leftIcon || !!rightIcon), customCSS]}
    onClick={isLoading ? noop : onClick}
  >
    {leftIcon && <span css={iconCSS(size === 'large')}>{leftIcon}</span>}
    <span
      css={css`
        visibility: ${isLoading ? 'hidden' : 'visible'};
      `}
    >
      {children}
    </span>
    {rightIcon && <span>{rightIcon}</span>}
    {isLoading && (
      <span css={loaderWrapperCSS}>
        <Loader inverted={variant !== 'secondary'} />
      </span>
    )}
  </button>
);

export default Button;
