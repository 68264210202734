import { z } from 'zod';

const BasePolicyInfoSchema = z.object({
  dwelling_coverage_limit: z.number(),
  personal_property_limit: z.number(),
  liability_coverage_limit: z.number(),
  wind_hail_coverage_presence: z.boolean(),
  flood_insurance_policy_presence: z.boolean(),
});

export const PolicyInfoSchema = BasePolicyInfoSchema.partial();
export const NonOptionalPolicyInfoSchema = BasePolicyInfoSchema;
