import { useQuery } from '@tanstack/react-query';
import api from '../api';
import type { WPItem } from '../../types/matic-content.type';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { dataModifier } from './use-matic-content-query.utils';

export default function useMaticContentQuery(categoryId = 78) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.maticContent, categoryId],
    throwOnError: false,
    queryFn: async () => {
      const response = await api
        .get<WPItem[]>(API_PATH.maticContent.replace(':categoryId', `${categoryId}`));

      return dataModifier(response || [], categoryId);
    },
  });
}
