import type { FC } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import useSendMagicLink from '../../api/authentification/use-send-magic-link.ts';
import Illustration from '../../assets/svg/forbidden-sheet.svg?react';
import PageInfoContainer from '../../components/PageInfoContainer/PageInfoContainer.tsx';
import ROUTES from '../../constants/routes.ts';
import Button from '../../shared/components/Button/Button.tsx';

import useTrackPage from '../../hooks/use-track-page.ts';
import useSegment from '../../hooks/use-segment.ts';
import type { TargetPage } from '../../types/target-page.ts';
import { ANALYTICS_SESSION_GID } from '../../constants/browser-storage.ts';
import type { SendMagicLinkPayload } from '../../types/account.type.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import { reportAPIExecutionError, reportErrorMessage } from '../../utils/error.util.ts';
import { illustrationCSS } from './index.style.ts';

const LinkExpiredLayout: FC = () => {
  const [searchParams] = useSearchParams();
  const accountGid = searchParams.get('gid');
  const personGid = searchParams.get('person_gid');
  const targetPage = searchParams.get('target_page');
  const navigate = useNavigate();
  const { mutateAsync: sendMagicLink, isPending: isSendPending } = useSendMagicLink();
  useTrackPage(AnalyticPageKey.LinkExpired);

  const { track } = useSegment();

  const handleResendLink = async (): Promise<void> => {
    if (!personGid && !accountGid) {
      reportErrorMessage(`No person gid or account gid found in URL. 
      person_gid=${personGid}, account_gid=${accountGid}`);
      navigate(ROUTES.problem);
      return;
    }

    const sessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);

    track('Authenticate Link Sent', {
      page: AnalyticPageKey.LinkExpired,
    });

    const params: SendMagicLinkPayload = {};

    if (personGid) {
      params.person_gid = personGid;
    }
    if (accountGid) {
      params.gid = accountGid;
    }
    if (targetPage) {
      params.target_page = targetPage as TargetPage;
    }
    if (sessionGid) {
      params.session_gid = sessionGid;
    }

    try {
      const res = await sendMagicLink(params);
      const queryParams = new URLSearchParams({
        gid: accountGid || '',
        person_gid: personGid || '',
        email: res.account.email,
        target_page: targetPage || '',
      });
      navigate(`${ROUTES.checkEmail}?${queryParams.toString()}`);
    }
    catch (error) {
      reportAPIExecutionError('Failed to send magic link', error);
      navigate(ROUTES.problem);
    }
  };

  return (
    <PageInfoContainer
      heading="Your link has expired"
      subHeading="That link is expired or has already been used, but we can send you a new link."
    >
      <Illustration css={illustrationCSS} />
      <Button fullWidth size="large" isLoading={isSendPending} onClick={handleResendLink}>
        Resend Link
      </Button>
    </PageInfoContainer>
  );
};

export default LinkExpiredLayout;
