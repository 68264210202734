import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries.ts';
import THEME from '../../../styles/theme.ts';

export const containerCSS = css`
  padding: 20px var(--side-padding) 0;
  --side-padding: 16px;

  ${mq[1]} {
    --side-padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const titleCSS = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
`;

export const cardInnerCSS = css`
  display: flex;
  gap: 12px;
  align-items: center;
  ${mq[1]} {
    gap: 20px;
    flex-direction: column;
    text-align: center;
  }
  ${mq[2]} {
    flex-direction: row;
    text-align: left;
  }
`;

export const iconWrapperCSS = css`
  svg {
    display: block;
  }
`;

export const buttonCSS = css`
  display: block;
  margin: 24px auto 0 auto;
`;

export const factorsCSS = css`
  margin-top: 20px;
`;

export const scoreCardLabelCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 36px;
  background-color: ${THEME.color.gray5};
  margin: 20px calc(var(--side-padding) * -1) 0;
  padding: 0 var(--side-padding);
  font-size: 14px;
  line-height: 20px;
  gap: 4px;

  span {
    font-weight: 700;
  }
`;
