import type { Factor, ScoreResponse } from '../../types/score.type.ts';
import { GAUGE_LABELS_NEGATIVE, GAUGE_LABELS_POSITIVE, getGaugeLabel } from '../insights/ScoreCard/ScoreCard.util.ts';
import { revertRiskScoreValue } from '../insights/ScoreCards/ScoreCards.util.ts';

export function addOrderNumber(arr?: Factor[], isMobile?: boolean): Array<Factor & { order: number }> {
  if (!arr) {
    return [];
  }
  if (isMobile) {
    return arr.map((factor, index) => ({ ...factor, order: index + 1 }));
  }

  const mid = Math.ceil(arr.length / 2);

  return arr.map((factor, index) => {
    const order = index < mid ? index * 2 + 1 : (index - mid) * 2 + 2;
    return { ...factor, order };
  });
}

export function getScoreLabels(
  riskScoreData: ScoreResponse | undefined,
  protectionScoreData: ScoreResponse | undefined,
  isQuotePresent: boolean,
) {
  const [protectionScore, protectionScoreMax]
  = [protectionScoreData?.score.value, protectionScoreData?.score.max_value];
  const [riskScore, riskScoreMax] = [riskScoreData?.score.value, riskScoreData?.score.max_value];

  const hasProtectionScoreData = typeof protectionScore === 'number' && typeof protectionScoreMax === 'number';
  const hasRiskScoreData = typeof riskScore === 'number' && typeof riskScoreMax === 'number';

  const protectionScoreLabel = hasProtectionScoreData && isQuotePresent
    ? getGaugeLabel(protectionScore, protectionScoreMax, GAUGE_LABELS_POSITIVE)
    : '';
  const riskScoreLabel = hasRiskScoreData && isQuotePresent
    ? getGaugeLabel(revertRiskScoreValue(riskScore, riskScoreMax)!, riskScoreMax, GAUGE_LABELS_NEGATIVE)
    : '';

  return { riskScoreLabel, protectionScoreLabel };
}

export const getHeadingLabel = (label: string) => (label ? <>: <span>{label}</span></> : label);
